<template>
    <b-container class="profile-route" fluid>
        <profile-header :view="view" />
        <b-container v-if="view ==='edit'" fluid>
            <b-row class="mt-3">
                <b-col lg="4">
                    <b-row class="justify-content-md-center pl-6">
                        <b-col lg="12">
                            <span class="player-info-label">{{ $t('editProfile.selectAvatar') }}</span>
                        </b-col>
                    </b-row>
                    <div class="avatar-container">
                        <avatar :avatar="selectedAvatar? selectedAvatar : null" />
                    </div>
                    <b-row
                        v-if="isImageUploadEnabled"
                        class="justify-content-md-center"
                    >
                        <b-col lg="12">
                            <span
                                class="btn upload-button mr-2"
                                role="button"
                                @click="viewTypeChanged('upload')"
                            >{{ $t('editProfile.uploadPhoto') }} </span>
                            <span class="color-help">{{ $t('editProfile.uploadText') }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <avatar-selection
                            :avatars="avatars"
                            :selected-avatar="selectedAvatar"
                            @avatar-selected="selectAvatar"
                        />
                    </b-row>
                </b-col>
                <b-col class="profile-divider" lg="7">
                    <b-row>
                        <b-col class="mb-4" lg="12">
                            <div class="color-help">*{{ $t('editProfile.requiredFields') }}</div>
                        </b-col>
                    </b-row>
                    <div class="plainfields-container">
                        <div
                            v-if="isPlainVisible('nickName')"
                            class="form-group"
                        >
                            <label class="float-left player-info-label"> {{ $t('editProfile.nickName') }}<span
                                v-if="isPlainRequired('nickName')"
                            >*</span></label>
                            <b-form-input
                                v-model="v$.player.nickName.$model"
                                :class="{
                                    'is-valid': !v$.player.nickName.$error && v$.player.nickName.$dirty && player.nickName,
                                    'is-invalid': v$.player.nickName.$error
                                }"
                                :formatter="(event) => formatInput(event,26)"
                                class="player-info-input"
                                trim
                                type="text"
                                @blur="v$.player.nickName.$touch"
                            />
                            <div class="input-error-container">
                                <small
                                    v-for="error of v$.player.nickName.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }} </small>
                            </div>
                        </div>
                        <div
                            v-if="isPlainVisible('firstName')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('firstName') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.name') }}
                            </label>
                            <b-form-input
                                v-model="v$.player.firstName.$model"
                                :class="{
                                    'is-valid': !v$.player.firstName.$error && v$.player.firstName.$dirty && player.firstName,
                                    'is-invalid': v$.player.firstName.$error
                                }"
                                class="player-info-input"
                                name="name"
                                trim
                                type="text"
                                @blur="v$.player.firstName.$touch"
                            />
                            <div class="input-error-container">
                                <small
                                    v-for="error of v$.player.firstName.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }} </small>
                            </div>
                        </div>
                        <div
                            v-if="isPlainVisible('lastName')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('lastName') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.surname') }}
                            </label>
                            <b-form-input
                                v-model="v$.player.lastName.$model"
                                :class="{
                                    'is-valid': !v$.player.lastName.$error && v$.player.lastName.$dirty && player.lastName,
                                    'is-invalid': v$.player.lastName.$error
                                }"
                                class="player-info-input"
                                name="surname"
                                trim
                                type="text"
                                @blur="v$.player.lastName.$touch"
                            />
                            <div class="input-error-container">
                                <small
                                    v-for="error of v$.player.lastName.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }} </small>
                            </div>
                        </div>
                        <div
                            v-if="isPlainVisible('birthDate')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('birthDate') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.birthDate') }}
                            </label>
                            <date-picker
                                :disabled-dates="disabledDates"
                                :input-class="{
                                    'player-info-input': true,
                                    'is-valid': !v$.player.birthDate.$error && v$.player.birthDate.$dirty && player.birthDate,
                                    'is-invalid': v$.player.birthDate.$error
                                }"
                                :language="datePickerLanguage"
                                :value="playerBirthDate"
                                clear-button
                                initial-view="year"
                                maximum-view="year"
                                minimum-view="day"
                                @cleared="v$.player.birthDate.$touch"
                                @selected="selectBirthDate"
                            />
                            <div class="input-error-container">
                                <small
                                    v-for="error of v$.player.birthDate.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }} </small>
                            </div>
                        </div>
                        <div class="mobile-col">
                            <profile-mobile-input
                                v-if="isPlainVisible('mobilePhone')"
                                :incoming-mobile="player.mobile"
                                :is-valid-response="isPhoneNumberValid"
                                :registration-channel="registrationChannel"
                                @phone-updated="updatePhone"
                            />

                            <div class="input-error-container">

                                <small
                                    v-for="error of v$.formattedMobile.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }} </small>
                            </div>
                        </div>
                        <div
                            v-if="isPlainVisible('email')"
                            class="form-group"
                        >
                            <label
                                :class="{'required-field': isPlainRequired('email') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.email') }}
                            </label>
                            <b-form-input
                                v-model="v$.playerEmail.$model"
                                :class="{
                                    'is-valid': !v$.playerEmail.$error && v$.playerEmail.$dirty && playerEmail,
                                    'is-invalid': v$.playerEmail.$error
                                }"
                                class="player-info-input"
                                name="email"
                                trim
                                type="email"
                                @blur="v$.playerEmail.$touch"
                                @input="setPlayerEmail"
                            />
                            <div class="input-error-container">

                                <small
                                    v-for="error of v$.playerEmail.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }}</small>
                            </div>
                        </div>
                        <div
                            v-if="isPlainVisible('loyalty')"
                            class="form-group"
                        >
                            <label class="float-left player-info-label">{{ profilePlainFields.loyalty.label }}<span
                                v-if="isPlainRequired('loyalty')"
                            >*</span></label>
                            <b-form-input
                                v-model="v$.player.loyaltyId.$model"
                                :class="{
                                    'is-valid': !v$.player.loyaltyId.$error && v$.player.loyaltyId.$dirty && player.loyaltyId,
                                    'is-invalid': v$.player.loyaltyId.$error
                                }"
                                class="player-info-input"
                                type="text"
                                @blur="v$.player.loyaltyId.$touch"
                            />
                            <div class="input-error-container">

                                <small
                                    v-for="error of v$.player.loyaltyId.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }} </small>
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <profile-dropdown-fields
                            @update-player-profile="playerDropdownChoicesUpdated"
                        />
                    </b-row>
                    <b-row v-if="isPlainVisible('fileUpload')" class="my-4">
                        <b-col>
                            <label
                                :class="{'required-field': isPlainRequired('fileUpload') }"
                                class="float-left player-info-label"
                            >
                                {{ $t('editProfile.uploadFileLabel') }}
                            </label>
                            <file-upload
                                :has-error="v$.player.uploadedFileUrl.$error"
                                :max-size="15"
                                :uploaded-file="player.uploadedFileUrl"
                                @file-updated="updateFile"
                            />
                            <div class="input-error-container">
                                <small
                                    v-for="error of v$.player.uploadedFileUrl.$errors"
                                    :key="error.$uid"
                                    class="error"
                                >{{ error.$message }} </small>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="form-error-container">
                        <b-col>
                            <div>
                                <strong v-if="errorMessageToShow" class="text-danger form-error">
                                    {{ errorMessageToShow }}
                                </strong>
                            </div>
                        </b-col>
                    </b-row>
                    <div
                        class="save-button"
                        role="button"
                        @click="saveProfile"
                    >
                        {{ $t('editProfile.save') }}
                    </div>
                </b-col>
            </b-row>

        </b-container>
        <b-container v-if="view ==='upload'" fluid>
            <upload-photo
                @back="viewTypeChanged"
                @image="setUploadedPhoto"
            />
        </b-container>
        <b-container v-if="view ==='crop'" fluid>
            <crop-photo
                :uploaded-photo="uploadedPhoto"
                @newPhoto="viewTypeChanged"
            />
        </b-container>
    </b-container>
</template>

<script>
    import emitter from '@/utils/emitter'
    import restApi from '@/utils/rest-api'
    import taskUtil from '@/utils/task-util'
    import playerService from '@/services/player-service'
    import avatarService from '@/services/avatar-service'
    import DatePicker from 'vuejs-datepicker'
    import 'bootstrap/dist/css/bootstrap.css'
    import ProfileMobileInput from '@/components/ProfileMobileInput'
    import UploadPhoto from '@/components/UploadPhoto'
    import CropPhoto from '@/components/CropUploadedPhoto'
    import AvatarSelection from '@/components/AvatarSelection'
    import ProfileDropdownFields from '@/components/ProfileDropdownFields'
    import ProfileHeader from '@/components/ProfileHeader'
    import dateConversion from '@/utils/date-conversion'
    import { el, en } from 'vuejs-datepicker/dist/locale'
    import Avatar from '@/components/Avatar'
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
    import badWordsUtil from '@/utils/bad-words'
    import FileUpload from '@/components/FileUpload'
    import useVuelidate from '@vuelidate/core'
    import { email, helpers, maxLength, minLength, requiredIf } from '@vuelidate/validators'

    const noBadWords = (value) => {
        return !(value && badWordsUtil.hasBadWords(value))
    }

    const universalAlphaCharactersOnly = (value) => {
        const regex = new RegExp(/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF Ά-ωΑ-ώ\s]{2,26}$/i)

        return !value || regex.test(value)
    }
    export default {
        name: 'EditProfile',
        components: {
            DatePicker,
            UploadPhoto,
            CropPhoto,
            Avatar,
            AvatarSelection,
            ProfileDropdownFields,
            ProfileMobileInput,
            ProfileHeader,
            FileUpload
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                view: 'edit',
                avatars: [],
                selectedAvatar: null,
                uploadedPhoto: '',
                isFormValid: true,
                disabledDates: {
                    from: new Date()
                },
                registrationChannel: '',
                formattedMobile: null,
                isPhoneNumberValid: true,
                isMobileVerified: true,
                playerEmail: '',
                playerBirthDate: null,
                minutesToWait: null,
                errorMessageToShow: ''
            }
        },
        validations() {
            return {
                playerEmail: {
                    requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isPlainRequired('email'))),
                    email: helpers.withMessage(this.$t('generalErrors.email'), email)
                },
                formattedMobile: {
                    validPhone: helpers.withMessage(this.$t('generalErrors.invalidPhone'), () => {
                        return this.isPlainRequired('mobilePhone') ? (this.isPhoneNumberValid && this.formattedMobile) : true
                    })
                },
                player: {
                    nickName: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isPlainRequired('nickName'))),
                        maxlength: helpers.withMessage(this.$t('generalErrors.maxLength', { maxLength: 26 }), maxLength(26)),
                        noBadWords: helpers.withMessage(this.$t('generalErrors.noBardWords'), noBadWords)
                    },
                    firstName: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isPlainRequired('firstName'))),
                        universalAlphaCharactersOnly: helpers.withMessage(this.$t('generalErrors.alpha'), universalAlphaCharactersOnly),
                        minLength: helpers.withMessage(this.$t('generalErrors.minLength', { minLength: 2 }), minLength(2))
                    },
                    lastName: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isPlainRequired('lastName'))),
                        universalAlphaCharactersOnly: helpers.withMessage(this.$t('generalErrors.alpha'), universalAlphaCharactersOnly),
                        minLength: helpers.withMessage(this.$t('generalErrors.minLength', { minLength: 2 }), minLength(2))
                    },
                    birthDate: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isPlainRequired('birthDate')))
                    },
                    loyaltyId: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isPlainRequired('loyalty'))),
                        maxlength: helpers.withMessage(this.$t('generalErrors.maxLength', { maxLength: 13 }), maxLength(13))
                    },
                    uploadedFileUrl: {
                        requiredIf: helpers.withMessage(this.$t('generalErrors.required'), requiredIf(() => this.isPlainRequired('fileUpload')))
                    }
                }
            }
        },
        computed: {
            player: {
                get() {
                    return this.$store.getters.getCurrentUser
                },
                set(value) {
                    this.$store.commit('setPlayer', value)
                }
            },
            profilePlainFields() {
                return this.$store.getters.getPlayerProfileFields
            },
            profileDropdownFields() {
                return this.$store.getters.getPlayerProfileDropdownFields
            },
            isFileUploadEnabled() {
                return this.profilePlainFields?.fileUpload || false
            },
            isImageUploadEnabled() {
                return this.profilePlainFields.imageUpload
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            playerBadges() {
                return this.player?.playerBadges
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return (missionId && taskId) ? this.$store.getters.getTask(missionId, taskId) : null
            },
            datePickerLanguage() {
                if (this.appLanguage === 'el') {
                    return el
                } else {
                    return en
                }
            },
            appLanguage() {
                return this.$store.getters.getLanguage
            }
        },
        watch: {
            player: {
                handler(newVal) {
                    if (newVal) {
                        this.initSelectedAvatar()
                        this.playerBirthDate = newVal.birthDate
                        this.registrationChannel = newVal.channel
                        this.playerEmail = this.player.email
                    }
                },
                immediate: true
            }
        },
        mounted() {
            emitter.on('otp-check-response', (otpCheck) => {
                this.isMobileVerified = otpCheck
                if (otpCheck === true) {
                    this.saveProfile()
                }
            })
        },
        async created() {
            emitter.on('croppedAvatar', (payload) => {
                if (payload) {
                    this.avatars.unshift({ imageUrl: payload.url })
                    this.selectedAvatar = this.avatars[0]
                    this.view = 'edit'
                }
            })

            const response = await avatarService.getAvatars()
            this.avatars = response.data

            let missionId = parseInt(this.$route?.params?.missionId)
            let taskId = parseInt(this.$route?.params?.taskId)
            if (missionId && taskId) {
                const isInvalid = await this.$store.dispatch('startTask', { missionId, taskId })
                if (isInvalid) {
                    await this.$router.push({ path: '/missions/' + missionId, replace: true })
                }
            }
        },
        methods: {
            initSelectedAvatar() {
                for (let avatar of this.avatars) {
                    if (this.player && this.player.avatar && avatar.id === this.player.avatar.id) {
                        this.selectedAvatar = avatar
                        break
                    }
                }
                if (!this.selectedAvatar) {
                    this.selectedAvatar = this.player.avatar
                }
            },
            isPlainVisible(plainFieldName) {
                return this.profilePlainFields[plainFieldName]?.visible
            },
            formatInput(name, maxLength) {
                return name.substring(0, maxLength)
            },
            isPlainRequired(plainFieldName) {
                if (plainFieldName === 'mobilePhone') {
                    return this.profilePlainFields.mobilePhone?.required || this.registrationChannel === 'SMS'
                }
                if (plainFieldName === 'email') {
                    return this.profilePlainFields.email?.required || this.registrationChannel === 'EMAIL' || !this.registrationChannel
                }
                return this.profilePlainFields[plainFieldName]?.required
            },
            selectBirthDate(date) {
                this.player.birthDate = dateConversion.toIsoString(date)
                this.v$.player.birthDate.$touch()
            },
            setPlayerEmail(email) {
                this.playerEmail = email
                this.v$.playerEmail.$touch()
            },
            selectAvatar(avatar) {
                this.selectedAvatar = avatar
            },
            playerDropdownChoicesUpdated(value) {
                for (let field in value) {
                    this.player[field] = value[field]
                }
            },
            async saveProfile() {
                const isFormCorrect = await this.v$.$validate()
                if (!isFormCorrect) {
                    console.log('v$.errors =>', this.v$.$errors)
                    console.log('v$.silentErrors =>', this.v$.$silentErrors)
                    return
                }

                if (!this.isMobileVerified && this.player.channel === 'SMS') {
                    emitter.emit('openOtpModal', this.formattedMobile)
                    return
                }
                if ((this.player.channel === 'EMAIL' || !this.player.channel) && this.playerEmail && (this.playerEmail.toLowerCase() !== this.player?.email?.toLowerCase())) {
                    try {
                        await playerService.changeEmail(this.playerEmail)
                        emitter.emit('openEmailVerificationModal')
                    } catch (error) {
                        this.handleChangeEmailError(error)
                        return
                    }
                }

                this.player.email = this.playerEmail
                this.player.mobile = this.formattedMobile
                if (this.selectedAvatar) {
                    this.player.avatar = { ...this.selectedAvatar, alias: `avatarOfPlayerId${this.player.id}` }
                }

                this.deleteFieldsWithEmptyValues()

                playerService.updateCurrentPlayer(this.player).then(() => {
                    this.player.avatar = null
                    this.$store.dispatch('refreshCurrentUser')
                    if (this.task && this.task.taskType &&
                        this.task.taskType.alias === 'ACTION_PROFILE'
                    ) {
                        let points = this.task.points
                        let finished = true
                        taskUtil.updateTask(this.mission, this.task, points, finished, this.$router)
                    } else {
                        this.$router.push({ path: `/home` })
                    }
                }).catch((error) => {
                    this.handleUpdatePlayerError(error)
                })
            },
            handleUpdatePlayerError(error) {
                if (error.response?.data?.code === 'INVALID_MOBILE') {
                    this.isPhoneNumberValid = false
                    this.errorMessageToShow = this.$t('editProfile.mobileError')
                } else if (error.response?.data?.code === 'INVALID_EMAIL') {
                    this.errorMessageToShow = this.$t('editProfile.error')
                } else if (error.response?.data?.code === 'PLAYER_EXISTS') {
                    if (this.registrationChannel === 'SMS') {
                        this.errorMessageToShow = this.$t('editProfile.playerExistsWithEmail')
                    } else if (this.registrationChannel === 'EMAIL') {
                        this.errorMessageToShow = this.$t('editProfile.playerExistsWithMobile')
                    }
                }
                setTimeout(() => {
                    this.errorMessageToShow = null
                }, 10000)
            },
            handleChangeEmailError(error) {
                if (error.response?.data?.code === 'PLAYER_EXISTS') {
                    this.errorMessageToShow = this.$t('editProfile.playerExistsWithEmail')
                } else if (error.response?.data?.errorCode === 'LAST_REQUESTED_OTP_STILL_ACTIVE') {
                    const minutes = (new Date(error.response.data.timeUntilNextRequest).getTime() - Date.now()) / 1000 / 60
                    this.minutesToWait = Math.ceil(minutes)
                    this.errorMessageToShow = this.$t('editProfile.pleaseTryAgain', { minutesToWait: this.minutesToWait })
                    setTimeout(() => {
                        this.errorMessageToShow = null
                    }, minutes * 60 * 1000)
                } else {
                    this.errorMessageToShow = this.$t('editProfile.emailValidationFailed')
                }
                setTimeout(() => {
                    this.errorMessageToShow = null
                }, 10000)
            },
            viewTypeChanged(view) {
                this.view = view
            },
            setUploadedPhoto(image) {
                this.uploadedPhoto = URL.createObjectURL(image)
                this.view = 'crop'
            },
            goToHome() {
                this.$router.push({ path: `/home` })
            },
            updatePhone(payload) {
                this.isPhoneNumberValid = payload.isPhoneNumberValid
                this.formattedMobile = payload.playerMobile.formattedNumber
                if (this.isPhoneNumberValid && (this.formattedMobile !== this.player.mobile)) {
                    this.isMobileVerified = false
                } else {
                    this.isMobileVerified = true
                }
            },
            deleteFieldsWithEmptyValues() {
                Object.entries(this.player).forEach(([key, value]) => {
                    if (typeof value === 'string') {
                        this.player[key] = this.player[key].trim()
                    }
                    if (this.player[key] === '') {
                        delete this.player[key]
                    }
                })
            },
            async updateFile(file) {
                if (file) {
                    const [data, error] = await this.universalTryCatch(restApi.uploadDocFile('uploads/documents', file))

                    const successMessage = this.$t('editProfile.fileUploadedSuccessfully')

                    if (data) {
                        this.player.uploadedFileUrl = data.data.url
                    }
                    this.displayToast(error, successMessage)
                } else {
                    this.player.uploadedFileUrl = ''
                }
                this.v$.player.uploadedFileUrl.$touch()
            },
            displayToast(error, successMessage) {
                if (error) {
                    this.$bvToast.toast(error.response?.data?.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast(successMessage, {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.profile-route {
    padding: 1rem 0 2rem;
}

.required-field::after {
    content: "*";
}

.form-input {
    width: 100%;
    background-color: rgb(255 255 255 / 75%);
    border: 1px solid var(--black);
    border-radius: 5px;
    height: 40px;
    color: var(--black);
    font-size: 100%;
    padding-left: 8px;
    font-weight: 700;
}

.plainfields-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 1rem;
    width: 100%;
    align-items: flex-start;
}

.form-group {
    width: calc((100% - 1rem) / 2);
    margin-bottom: 0;
    position: relative;
}

.mobile-col {
    display: flex;
    flex-direction: column;
    width: calc((100% - 1rem) / 2);
    position: relative;
}

.mobile-col .form-group {
    width: 100%;
}

.readonly {
    cursor: default;
}

.avatar-container {
    padding: 1rem 0 0;
    max-width: 150px;
    margin: 0 auto;
}

.color-help {
    color: var(--main-content-text);
    text-align: left;
}

.div-form-style {
    margin-left: 50%;
}

.player-info-label {
    font-size: 1.3vw;
    color: var(--main-content-text);
    font-weight: 700;
}

.save-button {
    margin-top: 1rem;
    margin-left: auto;
    min-width: 100px;
    height: 40px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 1px solid transparent;
    background: var(--secondary-color);
    color: var(--white);
    transition: all 0.2s ease-in-out;
    outline: none;
    display: grid;
    place-items: center;
}

.otp-button {
    background: rgb(255 255 255 / 75%);
    border-radius: 6px;
    border: 1px solid var(--black);
    color: var(--primary-color);
    font-weight: 700;
    transition: all 0.1s ease-in-out;
    padding: 5px 15px;
}

.upload-button {
    background: var(--white);
    padding: 0 0.5rem;
    font-size: 100%;
    border-radius: 6px;
    color: var(--primary-color);
    font-weight: 700;
    transition: all 0.1s ease-in-out;
}

.upload-button:active {
    transform: scale(0.9);
}

.save-button:active:enabled {
    transform: scale(0.9);
}

.save-button.disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

.profile-divider {
    border-left: 1px solid var(--main-content-text);
}

.form-error {
    border: 1px dashed var(--error);
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
}

.input-error-container {
    width: 100%;
    position: absolute;
    top: 100%;
}

.error {
    color: var(--error);
    font-size: 0.8rem;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
    display: block;
}

.form-error-container {
    height: 40px;
}

@media screen and (max-width: 1000px) {
    .avatar-container {
        max-width: 120px;
    }

    .player-info-label {
        font-size: 1rem;
    }

    .form-group,
    .mobile-col {
        width: 100%;
    }

    .profile-divider {
        border-left: none;
    }
}

@media screen and (min-width: 500px) {
    .save-button {
        width: 130px;
        height: 45px;
    }
}

@media screen and (min-width: 768px) {
    .save-button {
        width: 160px;
    }
}

</style>

<style lang="scss">
.player-info-input {
    width: 100%;
    background-color: rgb(255 255 255 / 75%);
    border: 1px solid var(--black);
    border-radius: 5px;
    height: 40px;
    color: var(--black);
    font-size: 100%;
    padding-left: 8px;
    font-weight: 700;
}

.vdp-datepicker__clear-button {
    position: absolute;
    right: 3%;
    top: 39%;
    font-size: 2rem;
}

.vdp-datepicker {
    .is-valid {
        border: 1px solid #28a745;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 1px #28a745;
    }

    .is-invalid {
        border: 1px solid var(--error);
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 1px var(--error);
    }
}

.input-tel__input,
.input-tel__label {
    cursor: unset !important;
}
</style>
